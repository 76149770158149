.child-details {
  .form-nav {
    justify-content: flex-end;
    margin-top: 1em;
  }

  .form-step {
    .form-step-heading {
      margin-bottom: 1em;
      @media screen and (max-width: $small-max) {
        padding: 0 1em;
      }
      & > p {
        white-space: pre-wrap;
      }
    }
  }

  .submit-details {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.progress-steps {
  padding: 0;
  margin: 0 0 1em 0;
  list-style: none;
  display: flex;
  justify-content: center;

  > .step {
    &.can-nav {
      cursor: pointer;
    }
    @mixin ProcessStep($stepWidth) {
      $circleSize: calc($stepWidth / 2.5);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: $stepWidth;

      .step-circle {
        position: relative;
        background-color: #dee0e1;
        width: $circleSize;
        height: $circleSize;
        border-radius: $circleSize;
        z-index: 5;
        display: flex;
        &::before {
          content: "";
          display: block;
          width: calc($stepWidth / 2);
          height: calc($circleSize / 3);
          position: absolute;
          top: calc(($circleSize / 2) - ($circleSize / 6));
          left: -#{calc(($stepWidth / 2) - ($circleSize / 2))};
          background-color: #dee0e1;
        }
        &::after {
          content: "";
          display: block;
          width: calc($stepWidth / 2);
          height: calc($circleSize / 3);
          position: absolute;
          top: calc(($circleSize / 2) - ($circleSize / 6));
          left: calc($circleSize / 2);
          background-color: #dee0e1;
        }

        &.complete {
          background-color: #277a9b;
          > img {
            width: $circleSize;
            z-index: 5;
            box-sizing: content-box;
            padding: calc($circleSize / 4 - 2px);
          }
          &::before {
            background-color: #277a9b;
          }
          &::after {
            background-color: #277a9b;
          }
        }
      }
    }

    @include ProcessStep(120px);
    @media screen and (max-width: $small-max) {
      @include ProcessStep(90px);
    }
    @media screen and (max-width: 499px) {
      @include ProcessStep(80px);
    }
    @media screen and (max-width: 430px) {
      @include ProcessStep(70px);
    }
    @media screen and (max-width: 380px) {
      @include ProcessStep(60px);
    }

    &:is(:first-child) {
      ::before {
        display: none;
      }
    }
    &:is(:last-child) {
      ::after {
        display: none;
      }
    }

    .step-name {
      display: inline-block;
      min-width: 100%;
      text-align: center;
      margin-top: 0.5em;
    }

    @media screen and (max-width: 430px) {
      margin-bottom: 2em;
      &:is(:nth-child(even)) {
        .step-name {
          transform: translateY(1em);
        }
      }
    }
  }
}

.passenger-actions.cin {
  justify-content: space-around;
  flex-wrap: wrap;

  > .cin.action-bar-item {
    flex-shrink: 0;
    &:last-child {
      margin-top: 1em;
    }
  }

  @media screen and (max-width: $small-max) {
    flex-direction: column;
    > .cin.action-bar-item {
      width: 100%;
      margin-bottom: 1em;
      &:last-child {
        margin-top: 0;
      }
      .ui.button {
        width: 100%;
      }
    }
  }
}

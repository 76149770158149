.ui.form {
  .field {
    @include DefineFont(18px);
    > label {
      font-weight: normal;
    }
    .ui.icon.input {
      > .icon {
        color: $primary-color;
      }
    }

    .ui.dropdown .text {
      overflow: visible;
      overflow-x: clip;
    }
  }

  .ui.radio.checkbox input:checked ~ .box:after,
  .ui.radio.checkbox input:checked ~ label:after {
    background-color: $primary-color;
  }

  .ui.checkbox input:checked ~ .box:after,
  .ui.checkbox input:checked ~ label:after {
    color: $primary-color;
  }

  .cascading-radio-group.field {
    > .field {
      display: flex;
      flex-wrap: wrap;

      > label {
        width: 100%;
      }

      > .ui.checkbox {
        margin-right: 2em !important;
      }

      > div:not(.checkbox) {
        width: 100%;
      }
    }
    &.required > .field > label::after {
      margin: -0.2em 0em 0em 0.2em;
      content: "*";
      color: $red;
    }
  }
}

.ui.form .disabled.field {
  opacity: 1;
}

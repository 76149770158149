.parent-modal {
  > .actions {
    display: flex;
    justify-content: space-between;
    .positive.ui.button {
      background-color: $primary-color;
      &:hover {
        background-color: darken($color: $primary-color, $amount: 12%);
      }
    }
    .negative.ui.button {
      background-color: transparent;
      color: $primary-color;
    }
  }
}

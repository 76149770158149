.system-page-content {
  background-color: $background-color;
  height: 100%;

  > .ui.grid {
    height: 100%;
    margin: 0;

    > .column:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .content {
        width: 24em;

        .image.logo {
          margin-bottom: 3em;
        }

        .ui.button {
          margin-top: 2em;
        }

        .ui.message {
          width: 100%;
        }
      }
    }
    & .column.background {
      background-color: white;
    }
  }
}

.offline {
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;

  .offline-panel {
    color: $text-color;
  }
}

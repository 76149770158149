@mixin DefineFont($size: $font-size, $weight: $regular-weight) {
  font-size: $size;
  font-weight: $weight;
}

@mixin LargeBoldText {
  @include DefineFont(23px, $bold-weight);
}

@mixin Regular16Text {
  @include DefineFont(16px);
}

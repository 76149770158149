body,
#root {
  background-color: $background-color;
  height: 100%;
  width: 100%;
}

.environment-banner {
  background-color: $red;
  border: 1px solid white;
  color: white;
  display: block;
  font-size: 20px;
  font-weight: 700;
  height: 36px;
  line-height: 30px;
  opacity: 0.6;
  position: fixed;
  left: -130px;
  text-align: center;
  bottom: 50px;
  transform: rotate(45deg);
  width: 400px;
  z-index: 10000;
  pointer-events: none;
}

.load-more-button {
  width: 100%;

  > .ui.button {
    display: block;
    margin: 0 auto;

    &.disabled,
    &:disabled {
      opacity: 1 !important;
    }
  }
}

.trip-details-header {
  font-size: 24px;
}

.ui.form .message.validation-message {
  display: block;
}

.step-validation-message {
  text-align: center;
  color: $red;
}

.red {
  color: $red;
}

// custom variables
$background-color: #f2f2f2;
$border-grey: #c8c8c8;
$border-colour: #ececec;
$hover-colour: #f5f8fb;
$box-grey: #979797;
$light-blue: #f2fbfd;
$white: #ffffff;
$black: #000000;
$blue2: #ecfbff;

// font weightings
$light-weight: 300;
$regular-weight: 400;
$semibold-weight: 600;
$bold-weight: 700;
$extrabold-weight: 800;

// responsive breakpoints (keep in sync with cinnamon breakpoints in setup.js)
$small-max: 899px;
$medium-min: 900px;
$medium-max: 1199px;
$large-min: 1200px;

// cinnamon overrides
// $import-google-fonts: true !default;
$font-url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,700,800&display=swap" !default;
$font-name: "Source Sans Pro" !default;
$font-family: $font-name, Arial, Helvetica, sans-serif !default;
$font-size: 16px !default;
$text-color: #444444;
$background-color: #ffffff;
$primary-color: #277a9b;
$positive-color: #2eac66;
$primary-color-hover: darken($primary-color, 5%);
$primary-color-focus: darken($primary-color, 5%);
$primary-color-down: darken($primary-color, 8%);
$primary-color-active: darken($primary-color, 10%);

.panel.ui.segment {
  border-color: $primary-color;
  border-top-width: 5px;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.4);

  &.blue {
    background-color: $blue2;
  }

  &:not(.inverted) {
    border-top-width: 5px !important;
  }
}

.column-grid {
  display: grid;
  grid-template-columns: 1fr; // overridden at component level
  grid-template-rows: auto;
  grid-auto-rows: auto;
  grid-gap: 1em; // overridden at component level

  .column-grid-item {
    position: relative;
    grid-column: auto / span 1; // overridden at component level
  }
}

.form-panel {
  border-top: solid 3px #277a9b;
  padding: 1em 0;
}

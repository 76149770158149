.login {
  .mobile-header {
    margin: 0 2em;
  }
}

.floating-panel.ui.segment {
  width: 40em;
  float: right;
  @media screen and (max-width: $small-max) {
    float: none;
    width: 100%;
    box-shadow: none;
  }
  @media screen and (min-width: $medium-min) and (max-width: $medium-max) {
    float: none;
    margin: auto !important;
  }
}

.login-error {
  margin-top: 1em;
  .ui.error.message {
    width: 100%;
  }
}

.ui.table {
  border-top: none;
  border-left: none;
  border-right: none;
}

.table-panel.ui.segment {
  padding-left: 2em;
  padding-right: 2em;

  .ui.table {
    margin-left: -2em;
    margin-right: -2em;
    width: calc(100% + 4em);

    th:first-child,
    td:first-child {
      padding-left: 2em;
    }

    th:last-child,
    td:last-child {
      padding-right: 2em;
    }
  }
}

.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

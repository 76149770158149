.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  $max-width: 1600px;

  .max {
    max-width: $max-width;
    flex-grow: 1;
    margin: 0 auto;
  }

  > header {
    padding: 1em 2em;
    @media screen and (max-width: $small-max) {
      padding-left: 1em;
      padding-right: 1em;
    }
    > .max {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .log-out {
        position: absolute;
        right: 0;
        .ui.button {
          display: flex;
          align-items: center;
          > .ui.image {
            margin-right: 0.5em;
          }
        }
      }

      @media screen and (max-width: 699px) {
        &:is(.logged-in) {
          justify-content: space-between;
          .log-out {
            position: relative;
          }
        }
      }
    }
  }
  .page-content {
    padding: 1em 2em;
    background-color: $light-blue;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    position: relative;

    @media screen and (max-width: $small-max) {
      padding-left: 0;
      padding-right: 0;
      background-color: $white;
      flex-grow: 0;
      .login-img {
        .mobile-mountains {
          display: block;
          width: 100%;
          max-height: 120px;
          position: relative;
          top: 1em;
        }
      }
    }
    @media screen and (min-width: $medium-min) {
      .login-img {
        background-image: url("~assets/splash.jpg");
        width: 100vw;
        height: 100%;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        left: 0;
      }
    }
  }
  > footer {
    display: flex;
    padding: 1em 2em;
    justify-content: center;
    height: 96px;
    .footer-text {
      @include DefineFont(14px);
    }
    .max {
      display: flex;
      justify-content: space-between;
    }
    @media screen and (max-width: $small-max) {
      padding-left: 1em;
      padding-right: 1em;
      > .max {
        flex-direction: column;
        .footer-text {
          padding-bottom: 1em;
        }
        .footer-logo {
          display: none;
        }
      }
    }
  }
}
